<template>
  <section>
    <div class="flex mt-6">
      <h2 class="text-4xl font-bold px-5 mx-auto justify-center text-center">
        Buy video tutorials, classes and more
      </h2>
    </div>

    <div class="container px-5 py-24 mx-auto">
      <div class="flex flex-wrap -m-4 text-gray-600 body-font">
        <div v-for="sku in skus" class="p-4 md:w-1/3">
          <div @click="buy()" class="cursor-pointer h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
            <img class="lg:h-48 md:h-36 w-full object-cover object-center"
                 :src=sku.image_path
                 :alt=sku.name>
            <div class="p-6">
              <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">{{ sku.category }}</h2>
              <h1 class="title-font text-lg font-medium text-gray-900 mb-3">{{ sku.name }}</h1>
              <p class="leading-relaxed mb-3">
                {{ sku.description }}
              </p>
              <div class="flex items-center flex-wrap" @click="buy()">
                <checkout-button
                    @click="buy()"
                    :sku-id="sku.id"
                    :loading="loading"
                />

                <span
                    class="text-gray-900 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm  py-1">
                  {{ sku.price_formatted }}
              </span>
                <span class="text-gray-400 inline-flex items-center leading-none text-sm">
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import CheckoutButton from "@/components/CheckoutButton";
import * as axios from "axios";

export default {
  name: 'Store',
  components: {CheckoutButton},
  data() {
    return {
      loading: false,

      stripe: Stripe(
          'pk_test_51IjkrPIeGFZgSn1xs2kiFBxmlOuGxCwx1m3hf8lkynJ6AFNfPEhxWAdr7Aa6Oljbk2B8TP50XfKLOarAaRpz6EJM00zQMuFCWu'
      ),

      skus: [
        {
          'id': '0f3f649a-55bd-4e2a-9997-30f905f86984',
          'name': 'Beginners Yoga Intro',
          'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          'category': 'SomeCategory',
          'price_formatted': '£10',
          'image_path': require('../assets/sample3.jpg'),
        },
      ],
    }
  },

  methods: {
    async buy() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      await axios.get('https://yf-stripe-session.sgb.workers.dev/')
          .then(response => {
            this.stripe.redirectToCheckout({sessionId: response.data.id});
          })
    },
  },

}
</script>
