<template>
  <div>
    <store></store>
  </div>
</template>

<script>

import Store from "@/components/Store";

export default {
  name: 'Home',
  components: {
    Store
  }
}
</script>
