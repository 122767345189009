<template>
  <div class="text-yfdarker inline-flex items-center md:mb-2 lg:mb-0 cursor-pointer" @click="buy()">
    <span v-if="!this.loading">Buy</span>
    <span v-else>Loading</span>
    <svg class="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none"
         stroke-linecap="round" stroke-linejoin="round">
      <path d="M5 12h14"></path>
      <path d="M12 5l7 7-7 7"></path>
    </svg>
  </div>
</template>
<script>

export default {
  name: 'CheckoutButton',
  props: {
    loading: false,
  }
}
</script>
