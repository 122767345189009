<template>
  <section class="text-gray-600 body-font">
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
      <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
        <img class="object-cover object-center rounded" alt="hero" src="../assets/yoga.jpg">
      </div>
      <div
          class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
        <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
          Purchase complete - check your inbox!
        </h1>
        <p class="mb-8 leading-relaxed">Thanks so much for purchasing, you should get an email shortly with a link to your video. I hope this helps you on your yoga journey.</p>
        <div class="flex justify-center">
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import Store from "@/components/Store";

export default {
  name: 'Home',
  components: {
    Store
  }
}
</script>
